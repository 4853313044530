<template>
  <v-container>
    <v-card class="mt-2">
      <v-toolbar
        dark
        dense
        color="green"
      >
        <v-col>
          <v-avatar
            tile
            dense
            size="55"
            color="green lighten-2"
            class="avatar rounded-lg"
          >
            <v-icon
              color="white"
              x-large
            >
              mdi-store
            </v-icon>
          </v-avatar>
        </v-col>

        <h3>Oportunidades</h3>
        <v-text-field
          v-model="search"
          class="mx-2"
          solo-inverted
          dense
          color="green"
          append-icon="mdi-magnify"
          label="Filtrar"
          single-line
          hide-details
        />
        <v-spacer />
        
        <v-btn
          small
          class="ma-4"
          color="red"
          :to="{ name: 'seguimientooport' }"
        >
          Seguimiento <v-icon>mdi-book</v-icon>
        </v-btn>
        
        <v-btn
          small
          class="ma-4"
          color="success"
          @click="consultar"
        >
          Consultar <v-icon>mdi-update</v-icon>
        </v-btn>
        
        <v-btn
          align="right"
          color="orange"
          dark
          small
          class="ma-2"
          :to="{ name: 'oportunidad' }"
        >
          Nuevo <v-icon>mdi-pencil</v-icon>
        </v-btn>
        
        <!-- <v-btn
          align="right"
          class="ma-4"
          small
          color="light-green"
          dark
        >
          <download-excel
            :data="actividades"
            :fields="json_fields"
            worksheet="My Worksheet"
            name="CatActividades.xls"
          >
            Excel
            <v-icon>mdi-download</v-icon>
          </download-excel>
        </v-btn> -->
      </v-toolbar>
    </v-card>

    <v-row>
      <v-col>
        <v-card elevation="10">
          <v-subheader class="">
            <h2 class="ma-4 pt-2 green--text">
              Oportunidades
            </h2>
            <v-spacer />
            
            <v-btn
              align="right"
              color="blue"
              dark
              small
              class="ma-1"
              :to="{ name: 'pospuestas' }"
            >
              Pospuestas
            </v-btn>
            <v-btn
              align="right"
              color="red"
              dark
              small
              class="ma-1"
              :to="{ name: 'perdidas' }"
            >
              Perdidas
            </v-btn>
            <v-btn
              align="right"
              color="green"
              dark
              small
              class="ma-1"
              :to="{ name: 'ganadas' }"
            >
              Ganadas
            </v-btn>
          </v-subheader>

          <v-row>
            <v-col>
              <v-data-table
                heigth="400"
                :headers="headers"
                :items="misoportunidades"
                :search="search"
                dense
              >
                <template v-slot:item="{ item }">
                  <tr @click.once="editar(item)">
                    <td class="red--text">
                      {{ item.idoportunidad }}
                    </td>
                    <td>{{ item.asunto }}</td>
                    <td>{{ item.obs }}</td>
                    <td>{{ item.creada }}</td>
                    <td>{{ item.estatus }}</td>
                    <td>{{ item.nomcont }}</td>
                    <td>{{ item.nomuser }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters} from 'vuex'

export default {
  data () {
    return {
      search: '',
      misoportunidades: [],
      headers: [
        { text: 'Id.', value: 'idoportunidad' },
        { text: 'Título', value: 'asunto' },
        { text: 'Descripción de Oportunidad', value: 'obs' },
        { text: 'Creada', value: 'creada' },
        { text: 'Estatus', value: 'estatus' },
        { text: 'Contacto', value: 'nomcont' },
        { text: 'Asesor', value: 'numuser' },
      ],
    }
  },

  computed: {
    // ...mapGetters('contactos', ['getContacto','getNewContacto']),
    // ...mapGetters('clientes', ['getCliente']),
    ...mapGetters('login',['getdatosUsuario']),

  },

  created(){
    this.consultar()
  },

  methods: {
      
    consultar(){
      // limpiar
      this.misoportunidades= []
      // console.log(this.getdatosUsuario)
      // let id = this.getdatosUsuario.idusuariosweb
      // console.log('consultar', id)

      this.$http.get("v2/oportunidad.list" ).then(response=>{
        if (response.data.error===null){
          this.misoportunidades = response.data.result
            
          this.misoportunidades.forEach( element => {
            let cFecha =new Date(element.creada).toLocaleDateString(
              'es-MX', {
                timeZone: "UTC",
                month: 'short',
                day: 'numeric'
              }
            )
            element.creada = cFecha
            // element.fechastart = element.fechastart.substr(0,10)
            // element.hora = element.fechastart.substr(11,17)
          })

          console.log("oportunidades", this.misoportunidades)
        }
          
      }).catch(error=>{
        	console.log(error)
      })
    },
      
    editar(item){
      console.log("Renglonitem", item)
      this.$router.push({name:'oportunidad', params: {oportunidad:item}})
    },
  }
}

</script>

<style scoped>
.avatar{
  top: -10px;
}
</style>